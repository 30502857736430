import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en-US',
    // messages
    messages: {
        // 英语
        'en-US': require('./en-US.js'),
        // 韩语
        'ko-KR': require('./ko-KR.js'),
        // 日语
        'ja-JP': require('./ja-JP.js'),
        // 越南语
        'vi-VN': require('./vi-VN.js'),
        // 中文
        'zh-CN': require('./zh-CN.js'),
    }
});