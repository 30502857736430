module.exports = {
    "大": "스포츠카",
    "小": "비행기",
    "单": "보물",
    "双": "로켓",
    "设置语言": "언어 설정",
    "英语": "영어",
    "中文": "중국어",
    "韩语": "한국어",
    "日语": "일본어",
    "越南语": "베트남어",
    "任务单": "작업 목록",
    "key": "재생: {count}",
    "key2": "인기 추천",
    "key3": "인기 순위",
    "key4": "더보기",
    "key5": "추천 작업",
    "获取中": "로딩 중......",
    "刷新成功": "새로고침 완료",
    "首页": "홈",
    "任务记录": "작업 기록",
    "提现": "출금",
    "我的": "내 정보",
    "投票结果": "투표 결과",
    "可用余额": "사용 가능 잔액",
    "提交": "제출",
    "当前选号": "현재 선택 번호",
    "每注金额": "베팅 금액",
    "请输入金额": "금액을 입력하세요",
    "总共": "합계",
    "注": "베팅",
    "合计": "소계",
    "清空订单": "주문 비우기",
    "确认提交": "제출 확인",
    "期号": "회차 번호",
    "开奖号码": "당첨 번호",
    "未选择": "선택되지 않음",
    "金额错误！": "금액 오류!",
    "请选号！": "번호를 선택하세요!",
    "请填写金额！": "금액을 입력하세요!",
    "余额不足，请联系客服充值！": "잔액 부족, 고객센터에 문의하세요!",
    "未选中": "선택되지 않음",
    "请联系管理员领取该任务!": "관리자에게 문의하여 작업을 받으세요!",
    "开奖成功，期号：": "추첨 성공, 회차 번호: {count}",
    "key6": "정산 시간: {update_time}",
    "key7": "주문 시간: {create_time}",
    "key8": "금액: {money}",
    "key10": "수익 금액: {money}",
    "数据为空！": "데이터가 없습니다!",
    "充值": "충전",
    "我的钱包": "내 지갑",
    "详情": "상세 정보",
    "余额": "잔액",
    "个人报表": "개인 보고서",
    "账户明细": "계정 상세 내역",
    "个人中心": "개인 센터",
    "信息公告": "정보 공지",
    "在线客服": "온라인 고객센터",
    "请完成任务单后进入": "작업 목록을 완료한 후 들어오세요",
    "请联系客服充值": "고객센터에 문의하여 충전하세요",
    "请设置收款卡!": "수금 카드를 설정하세요!",
    "功能已禁用!": "기능이 비활성화되었습니다!",
    "账号下线": "계정 로그아웃",
    "登录/注册": "로그인/회원가입",
    "登录可享受更多服务！": "로그인하면 더 많은 서비스를 이용할 수 있습니다!",
    "收款卡信息": "수금 카드 정보",
    "添加收款卡": "수금 카드 추가",
    "提示:请绑定大型商业银行, 如需修改, 请您联系在线客服": "안내: 대형 상업 은행을 연동하세요. 수정이 필요하면 온라인 고객센터에 문의하세요.",
    "请设置姓名后再绑定银行卡！": "이름을 설정한 후 은행 카드를 연동하세요!",
    "请设置提现密码后再绑定银行卡！": "출금 비밀번호를 설정한 후 은행 카드를 연동하세요!",
    "填写收款卡": "수금 카드 입력",
    "请输入您的收款卡信息": "수금 카드 정보를 입력하세요",
    "分行名": "지점명",
    "请输入分行名称": "지점명을 입력하세요",
    "帐号": "계좌(일반)",
    "请选择帐号": "계좌(일반)를 선택하세요",
    "尊敬的用户, 为了保障您的资金安全, 请您绑定您的真实姓名和设置取款密码, 如果姓名与开户名不一致, 将无法取款": "존경하는 사용자님, 자금의 안전을 위해 실명을 연동하고 출금 비밀번호를 설정하세요. 이름과 계좌 명의가 일치하지 않으면 출금이 불가능합니다.",
    "确认绑卡": "카드 연동 확인",
    "请输入银行卡号": "은행 카드 번호를 입력하세요",
    "请选择银行": "은행을 선택하세요",
    "提现中心": "출금 센터",
    "提现记录": "출금 기록",
    "提现金额": "출금 금액",
    "全部": "전체",
    "1.单笔限额：最低，最高": "1. 1회 한도: 최소 {min}$, 최대 {max}$",
    "2.提现次数：一天最高提现次": "2. 출금 횟수: 하루 최대 {num}회",
    "3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "3. 입금 소요 시간: 일반적으로 5분 내외, 빠르면 2분 이내 입금",
    "限额说明": "한도 설명",
    "马上提现": "지금 출금하기",
    "请填写正确的金额": "정확한 금액을 입력하세요",
    "金额": "금액",
    "说明": "설명",
    "提交时间": "제출 시간",
    "审核时间": "검토 시간",
    "盈利金额": "수익 금액",
    "盈利计算公式 : 中奖金额 - 任务金额": "수익 계산 공식: 당첨 금액 - 작업 금액",
    "任务金额": "작업 금액",
    "充值金额": "충전 금액",
    "中奖金额": "당첨 금액",
    "系统公告": "시스템 공지",
    "基本信息": "기본 정보",
    "头像": "프로필 사진",
    "选择头像": "프로필 사진 선택",
    "确定": "확인",
    "真实姓名": "실명",
    "未设置": "설정되지 않음",
    "性别": "성별",
    "男": "남성",
    "女": "여성",
    "未知": "미확인",
    "绑定信息": "연동 정보",
    "已绑定": "연동됨",
    "无": "없음",
    "设置": "설정",
    "基本信息设置": "기본 정보 설정",
    "登录密码": "로그인 비밀번호",
    "资金密码": "자금 비밀번호",
    "语言": "언어",
    "退出登录": "로그아웃",
    "提现密码已设置，需要修改请联系客服": "출금 비밀번호가 설정되었습니다. 수정하려면 고객센터에 문의하세요.",
    "已设置": "설정 완료",
    "请输入用户名": "사용자 이름을 입력하세요",
    "请输入密码": "비밀번호를 입력하세요",
    "忘记密码？": "비밀번호를 잊으셨나요?",
    "没有账户？马上注册": "계정이 없으신가요? 지금 등록하세요",
    "登录": "로그인",
    "请输入用户名！": "사용자 이름을 입력하세요!",
    "请输入密码！": "비밀번호를 입력하세요!",
    "请输入用户名(6-12个英文字母或数字)": "사용자 이름을 입력하세요 (6-12자의 영문자 또는 숫자)",
    "请输入登录密码(6-12个英文字母或数字)": "로그인 비밀번호를 입력하세요 (6-12자의 영문자 또는 숫자)",
    "请输入邀请码": "초대 코드를 입력하세요",
    "我已经知晓并同意\"开户协议\"各项条约": "\"계정 개설 약관\"의 모든 조항에 동의합니다",
    "注册": "등록",
    "请输入邀请码！": "초대 코드를 입력하세요!",
    "请勾选下方开户协议！": "아래 계정 개설 약관을 선택하세요!",
    "下拉即可刷新": "아래로 당겨 새로고침",
    "释放即可刷新": "손을 놓으면 새로고침",
    "加载中": "로드 중",
    '请输入资金密码': '펀드 비밀번호를 입력해주세요',
    "联系": "연락하기",
    "7*24小时竭诚为您服务": "7*24시간 진심으로 서비스를 제공합니다",
    "热门推荐": "인기 추천",
    "次播放": "조회 수",
    "姓名": "이름",
    "请输入姓名": "이름을 입력하세요"
}

