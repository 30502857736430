module.exports = {
    "大": "Siêu xe",
    "小": "Máy bay",
    "单": "Kho báu",
    "双": "Tên lửa",
    "设置语言": "Cài đặt ngôn ngữ",
    "英语": "Tiếng Anh",
    "中文": "Tiếng Trung",
    "韩语": "Tiếng Hàn",
    "日语": "Tiếng Nhật",
    "越南语": "Tiếng Việt",
    "任务单": "Danh sách nhiệm vụ",
    "key": "Phát: {count}",
    "key2": "Gợi ý phổ biến",
    "key3": "Bảng xếp hạng phổ biến",
    "key4": "Xem thêm",
    "key5": "Nhiệm vụ được đề xuất",
    "获取中": "Đang tải......",
    "刷新成功": "Làm mới thành công",
    "首页": "Trang chủ",
    "任务记录": "Lịch sử nhiệm vụ",
    "提现": "Rút tiền",
    "我的": "Của tôi",
    "投票结果": "Kết quả bình chọn",
    "可用余额": "Số dư khả dụng",
    "提交": "Gửi",
    "当前选号": "Số hiện tại được chọn",
    "每注金额": "Số tiền đặt cược",
    "请输入金额": "Vui lòng nhập số tiền",
    "总共": "Tổng cộng",
    "注": "Cược",
    "合计": "Tổng số",
    "清空订单": "Xóa đơn hàng",
    "确认提交": "Xác nhận gửi",
    "期号": "Số kỳ",
    "开奖号码": "Số trúng thưởng",
    "未选择": "Chưa chọn",
    "金额错误！": "Số tiền không hợp lệ!",
    "请选号！": "Vui lòng chọn số!",
    "请填写金额！": "Vui lòng điền số tiền!",
    "余额不足，请联系客服充值！": "Số dư không đủ, vui lòng liên hệ dịch vụ khách hàng để nạp tiền!",
    "未选中": "Chưa được chọn",
    "请联系管理员领取该任务!": "Vui lòng liên hệ quản trị viên để nhận nhiệm vụ này!",
    "开奖成功，期号：": "Kết quả xổ số thành công, số kỳ: {count}",
    "key6": "Thời gian thanh toán: {update_time}",
    "key7": "Thời gian đặt hàng: {create_time}",
    "key8": "Số tiền: {money}",
    "key10": "Số tiền lãi: {money}",
    "数据为空！": "Dữ liệu trống!",
    "充值": "Nạp tiền",
    "我的钱包": "Ví của tôi",
    "详情": "Chi tiết",
    "余额": "Số dư",
    "个人报表": "Báo cáo cá nhân",
    "账户明细": "Chi tiết tài khoản",
    "个人中心": "Trung tâm cá nhân",
    "信息公告": "Thông báo",
    "在线客服": "Dịch vụ khách hàng trực tuyến",
    "请完成任务单后进入": "Vui lòng hoàn thành danh sách nhiệm vụ trước khi vào",
    "请联系客服充值": "Vui lòng liên hệ dịch vụ khách hàng để nạp tiền",
    "请设置收款卡!": "Vui lòng cài đặt thẻ nhận tiền!",
    "功能已禁用!": "Chức năng đã bị vô hiệu hóa!",
    "账号下线": "Tài khoản đã đăng xuất",
    "登录/注册": "Đăng nhập/Đăng ký",
    "登录可享受更多服务！": "Đăng nhập để tận hưởng thêm nhiều dịch vụ!",
    "收款卡信息": "Thông tin thẻ nhận tiền",
    "添加收款卡": "Thêm thẻ nhận tiền",
    "提示:请绑定大型商业银行, 如需修改, 请您联系在线客服": "Lưu ý: Vui lòng liên kết với ngân hàng thương mại lớn. Nếu cần chỉnh sửa, vui lòng liên hệ dịch vụ khách hàng trực tuyến.",
    "请设置姓名后再绑定银行卡！": "Vui lòng đặt tên trước khi liên kết thẻ ngân hàng!",
    "请设置提现密码后再绑定银行卡！": "Vui lòng đặt mật khẩu rút tiền trước khi liên kết thẻ ngân hàng!",
    "填写收款卡": "Điền thông tin thẻ nhận tiền",
    "请输入您的收款卡信息": "Vui lòng nhập thông tin thẻ nhận tiền của bạn",
    "分行名": "Tên chi nhánh",
    "请输入分行名称": "Vui lòng nhập tên chi nhánh",
    "帐号": "Tài khoản (thông thường)",
    "请选择帐号": "Vui lòng chọn tài khoản (thông thường)",
    "尊敬的用户, 为了保障您的资金安全, 请您绑定您的真实姓名和设置取款密码, 如果姓名与开户名不一致, 将无法取款": "Kính gửi người dùng, để bảo đảm an toàn cho tài khoản của bạn, vui lòng liên kết tên thật và đặt mật khẩu rút tiền. Nếu tên không khớp với tên tài khoản ngân hàng, bạn sẽ không thể rút tiền.",
    "确认绑卡": "Xác nhận liên kết thẻ",
    "请输入银行卡号": "Vui lòng nhập số thẻ ngân hàng",
    "请选择银行": "Vui lòng chọn ngân hàng",
    "提现中心": "Trung tâm rút tiền",
    "提现记录": "Lịch sử rút tiền",
    "提现金额": "Số tiền rút",
    "全部": "Tất cả",
    "1.单笔限额：最低，最高": "1. Hạn mức mỗi giao dịch: tối thiểu {min}$, tối đa {max}$",
    "2.提现次数：一天最高提现次": "2. Số lần rút tiền: tối đa {num} lần mỗi ngày",
    "3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "3. Thời gian xử lý: Thông thường trong khoảng 5 phút, nhanh nhất là 2 phút",
    "限额说明": "Giải thích hạn mức",
    "马上提现": "Rút tiền ngay",
    "请填写正确的金额": "Vui lòng nhập số tiền chính xác",
    "金额": "Số tiền",
    "说明": "Giải thích",
    "提交时间": "Thời gian gửi",
    "审核时间": "Thời gian duyệt",
    "盈利金额": "Số tiền lãi",
    "盈利计算公式 : 中奖金额 - 任务金额": "Công thức tính lãi: Số tiền trúng thưởng - Số tiền nhiệm vụ",
    "任务金额": "Số tiền nhiệm vụ",
    "充值金额": "Số tiền nạp",
    "中奖金额": "Số tiền trúng thưởng",
    "系统公告": "Thông báo hệ thống",
    "基本信息": "Thông tin cơ bản",
    "头像": "Ảnh đại diện",
    "选择头像": "Chọn ảnh đại diện",
    "确定": "Xác nhận",
    "真实姓名": "Tên thật",
    "未设置": "Chưa cài đặt",
    "性别": "Giới tính",
    "男": "Nam",
    "女": "Nữ",
    "未知": "Chưa rõ",
    "绑定信息": "Thông tin liên kết",
    "已绑定": "Đã liên kết",
    "无": "Không",
    "设置": "Cài đặt",
    "基本信息设置": "Cài đặt thông tin cơ bản",
    "登录密码": "Mật khẩu đăng nhập",
    "资金密码": "Mật khẩu tài chính",
    "语言": "Ngôn ngữ",
    "退出登录": "Đăng xuất",
    "提现密码已设置，需要修改请联系客服": "Mật khẩu rút tiền đã được cài đặt, vui lòng liên hệ dịch vụ khách hàng nếu cần chỉnh sửa.",
    "已设置": "Đã cài đặt",
    "请输入用户名": "Vui lòng nhập tên người dùng",
    "请输入密码": "Vui lòng nhập mật khẩu",
    "忘记密码？": "Quên mật khẩu?",
    "没有账户？马上注册": "Chưa có tài khoản? Đăng ký ngay",
    "登录": "Đăng nhập",
    "请输入用户名！": "Vui lòng nhập tên người dùng!",
    "请输入密码！": "Vui lòng nhập mật khẩu!",
    "请输入用户名(6-12个英文字母或数字)": "Vui lòng nhập tên người dùng (6-12 chữ cái hoặc số)",
    "请输入登录密码(6-12个英文字母或数字)": "Vui lòng nhập mật khẩu đăng nhập (6-12 chữ cái hoặc số)",
    "请输入邀请码": "Vui lòng nhập mã mời",
    "我已经知晓并同意\"开户协议\"各项条约": "Tôi đã đọc và đồng ý với các điều khoản trong \"Hợp đồng mở tài khoản\"",
    "注册": "Đăng ký",
    "请输入邀请码！": "Vui lòng nhập mã mời!",
    "请勾选下方开户协议！": "Vui lòng chọn vào Hợp đồng mở tài khoản bên dưới!",
    "下拉即可刷新": "Kéo xuống để làm mới",
    "释放即可刷新": "Thả ra để làm mới",
    "加载中": "Đang tải",
    '请输入资金密码': 'Vui lòng nhập mật khẩu quỹ',
    "联系": "Liên hệ",
    "7*24小时竭诚为您服务": "7*24 giờ tận tâm phục vụ bạn",
    "热门推荐": "Đề xuất phổ biến",
    "次播放": "Lượt xem",
    "姓名": "Họ tên",
    "请输入姓名": "Vui lòng nhập họ tên"
}
