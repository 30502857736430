import axios from "axios";
import api from "./api";
import Vue from "vue";
import { Toast } from "vant";
import qs from "qs";

let Base64 = require("js-base64").Base64;

Vue.use(Toast);
//创建axios实例对象  蜜桃源码网--www.mitaobo.com
const instance = axios.create({
  // baseURL: 'http://kongjianght.guogancs015.xyz/api', // 演示环境
  baseURL: "https://api.youtubepa.xyz/api", // 正式环境
  timeout: 5000, //默认超时时长
});

//请求拦截器
instance.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.headers = {
        "content-type": "application/x-www-form-urlencoded;charset=UTF-8", // 设置跨域头部
      };
    }
    if (localStorage.getItem("token")) {
      config.headers = {
        token: Base64.encode(localStorage.getItem("token")),
      };
    }
    config.headers["lang"] = localStorage.getItem("lang") || "en-US";
    return config;
  },
  (err) => {
    console.error("请求失败", err);
  }
);

//响应拦截器
instance.interceptors.response.use(
  (res) => {
    //此处对响应数据做处理
    if (res.data.msg === "鉴权错误" || res.data.code == 406) {
      localStorage.clear();
      this.$router.push({ path: "/Login" });
    }
    return res; // 返回解析后的对象
  },
  (err) => {
    // 响应错误处理
    return Promise.reject(err);
  }
);

//封装axios请求方法，参数为配置对象
//option = {method,url,params} method为请求方法，url为请求接口，params为请求参数
async function http(option = {}) {
  let result = process.env.NODE_ENV === "development" ? {} : null;
  const envParam = { env: process.env.NODE_ENV };
  if (option.method === "get" || option.method === "delete") {
    const params = { ...option.data, ...envParam };
    //处理get、delete请求
    await instance[option.method](api[option.url], { params })
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          result.data = res.data;
        } else {
          result = res.data;
          result.data = JSON.parse(Base64.decode(result.data));
        }
      })
      .catch((err) => {
        result = err;
      });
  } else if (option.method === "post" || option.method === "put") {
    const data = { ...option.data, ...envParam };
    //处理post、put请求
    await instance[option.method](api[option.url], qs.stringify(data))
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          result.data = res.data;
        } else {
          result = res.data;
          result.data = JSON.parse(Base64.decode(result.data));
        }
      })
      .catch((err) => {
        result = err;
      });
  }
  return process.env.NODE_ENV === "development" ? result.data : result;
}

export default http;
