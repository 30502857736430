<template>
  <Player controls>
    <Youtube :videoId="videoId" showFullscreenControl="true" :controls="params"/>
    <!--    <Embed-->
    <!--        embedSrc="https://www.youtube-nocookie.com/embed/DyTCOwB0DVw"-->
    <!--        mediaTitle="1"-->
    <!--        origin=""-->
    <!--        :params="params"-->
    <!--        @vmEmbedMessage="onMessage"-->
    <!--    />-->
    <!--    <DefaultUi/>-->
  </Player>
</template>

<script>
import {Player, Youtube} from '@vime/vue';

export default {
  name: 'YouTubePlayer',
  components: {
    Player,
    Youtube
  },
  props: {
    videoId: {
      type: String,
      required: true, // 必须传入 YouTube 视频 ID
    },
  },
  data() {
    return {
      params: {
        cc_load_policy: 0,
      }
    }
  },
  methods: {
  },
};
</script>

<style scoped lang="less">
.youtube-player {
  max-width: 800px;
  margin: 0 auto;
}
</style>
<!--<style>-->
<!--vm-player{-->
<!--  display: flex;-->
<!--  height: 100%;-->
<!--}-->
<!--</style>-->
