<template>
  <div class="container page">
    <van-nav-bar :title="$t('设置语言')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="items">
      <div v-for="(item, i) in langList" :key="i" class="item van-hairline--bottom" @click="selectHandle(item)">
        <div class="left">{{ $t(item.name) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langList: [
        {
          name: '英语',
          value: 'en-US'
        },
        {
          name: '韩语',
          value: 'ko-KR'
        },
        {
          name: '日语',
          value: 'ja-JP'
        },
        {
          name: '越南语',
          value: 'vi-VN'
        },
        {
          name: '中文',
          value: 'zh-CN'
        },
      ]
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    selectHandle(item) {
      this.$i18n.locale = item.value;
      // 本地缓存
      localStorage.setItem('lang', item.value);
      // 返回首页
      this.$router.push({path: '/'});
    }
  },
  created() {

  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.container .items {
  background-color: #fff;
  font-size: 30px;
  color: #000;
  padding: 0 40px;
}

.container .items .item {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}

.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.container .item .desc {
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}

.container .item .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
