module.exports = {
    '大': '跑车',
    '小': '飞机',
    '单': '成宝',
    '双': '火箭',
    '设置语言': '设置语言',
    '英语': '英语',
    '中文': '中文',
    '韩语': '韩语',
    '日语': '日语',
    '越南语': '越南语',
    '任务单': '任务单',
    key: '播放:{count}',
    key2: '热门推荐',
    key3: '人气排行',
    key4: '查看更多',
    key5: '推荐任务',
    "获取中": "获取中......",
    "刷新成功": "刷新成功",
    '首页': '首页',
    '任务记录': '任务记录',
    '提现': '提现',
    '我的': '我的',
    '投票结果': '投票结果',
    '可用余额': '可用余额',
    '提交': '提交',
    '当前选号': '当前选号',
    '每注金额': '每注金额',
    '请输入金额': '请输入金额',
    '总共': '总共',
    '注': '注',
    '合计': '合计',
    '清空订单': '清空订单',
    '确认提交': '确认提交',
    '期号': '期号',
    '开奖号码': '开奖号码',
    "未选择": "未选择",
    "金额错误！": "金额错误！",
    "请选号！": "请选号！",
    "请填写金额！": "请填写金额！",
    "余额不足，请联系客服充值！": "余额不足，请联系客服充值！",
    "未选中": "未选中",
    "请联系管理员领取该任务!": "请联系管理员领取该任务!",
    "开奖成功，期号：": "开奖成功，期号：{count}",
    key6: '结算时间：{update_time}',
    key7: '下单时间：{create_time}',
    key8: '金额：{money}',
    key10: '盈利金额：{money}',
    '数据为空！': '数据为空！',
    '充值': '充值',
    '我的钱包': '我的钱包',
    '详情': '详情',
    '余额': '余额',
    '个人报表': '个人报表',
    '账户明细': '账户明细',
    '个人中心': '个人中心',
    '信息公告': '信息公告',
    '在线客服': '在线客服',
    "请完成任务单后进入": "请完成任务单后进入",
    "请联系客服充值": "请联系客服充值",
    "请设置收款卡!": "请设置收款卡!",
    "功能已禁用!": "功能已禁用!",
    "账号下线": "账号下线",
    "登录/注册": "登录/注册",
    "登录可享受更多服务！": "登录可享受更多服务！",
    '收款卡信息': '收款卡信息',
    '添加收款卡': '添加收款卡',
    '提示:请绑定大型商业银行, 如需修改, 请您联系在线客服': '提示:请绑定大型商业银行, 如需修改, 请您联系在线客服',
    "请设置姓名后再绑定银行卡！": "请设置姓名后再绑定银行卡！",
    "请设置提现密码后再绑定银行卡！": "请设置提现密码后再绑定银行卡！",
    '填写收款卡': '填写收款卡',
    '请输入您的收款卡信息': '请输入您的收款卡信息',
    '分行名': '分行名',
    '请输入分行名称': '请输入分行名称',
    '帐号': '帐号',
    '请选择帐号': '请选择帐号',
    '尊敬的用户, 为了保障您的资金安全, 请您绑定您的真实姓名和设置取款密码, 如果姓名与开户名不一致, 将无法取款': '尊敬的用户, 为了保障您的资金安全, 请您绑定您的真实姓名和设置取款密码, 如果姓名与开户名不一致, 将无法取款',
    '确认绑卡': '确认绑卡',
    "请输入银行卡号": "请输入银行卡号",
    "请选择银行": "请选择银行",
    '提现中心': '提现中心',
    '提现记录': '提现记录',
    '提现金额': '提现金额',
    '全部': '全部',
    '1.单笔限额：最低，最高': '1.单笔限额：最低{min}$，最高{max}$',
    '2.提现次数：一天最高提现次': '2.提现次数：一天最高提现{num}次',
    '3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账': '3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账',
    '限额说明': '限额说明',
    '马上提现': '马上提现',
    "请填写正确的金额": "请填写正确的金额",
    '金额': '金额',
    '说明': '说明',
    '提交时间': '提交时间',
    '审核时间': '审核时间',
    '盈利金额': '盈利金额',
    '盈利计算公式 : 中奖金额 - 任务金额': '盈利计算公式 : 中奖金额 - 任务金额',
    '任务金额': '任务金额',
    '充值金额': '充值金额',
    '中奖金额': '中奖金额',
    '系统公告': '系统公告',
    '基本信息': '基本信息',
    '头像': '头像',
    '选择头像': '选择头像',
    '确定': '确定',
    '真实姓名': '真实姓名',
    '未设置': '未设置',
    '性别': '性别',
    "男": "男",
    "女": "女",
    '未知': '未知',
    '绑定信息': '绑定信息',
    "已绑定": "已绑定",
    '无': '无',
    '设置': '设置',
    '基本信息设置': '基本信息设置',
    '登录密码': '登录密码',
    '资金密码': '资金密码',
    '语言': '语言',
    '退出登录': '退出登录',
    "提现密码已设置，需要修改请联系客服": "提现密码已设置，需要修改请联系客服",
    "已设置": "已设置",
    '请输入用户名': '请输入用户名',
    '请输入密码': '请输入密码',
    '忘记密码？': '忘记密码？',
    '没有账户？马上注册': '没有账户？马上注册',
    '登录': '登录',
    "请输入用户名！": "请输入用户名！",
    "请输入密码！": "请输入密码！",
    '请输入用户名(6-12个英文字母或数字)': '请输入用户名(6-12个英文字母或数字)',
    '请输入登录密码(6-12个英文字母或数字)': '请输入登录密码(6-12个英文字母或数字)',
    '请输入邀请码': '请输入邀请码',
    '我已经知晓并同意"开户协议"各项条约': '我已经知晓并同意"开户协议"各项条约',
    '注册': '注册',
    "请输入邀请码！": "请输入邀请码！",
    "请勾选下方开户协议！": "请勾选下方开户协议！",
    '下拉即可刷新': '下拉即可刷新',
    '释放即可刷新': '释放即可刷新',
    '加载中': '加载中',
    '请输入资金密码': '请输入资金密码',
    '联系': '联系',
    '7*24小时竭诚为您服务': '7*24小时竭诚为您服务',
    '热门推荐': '热门推荐',
    '次播放': '次播放',
    "姓名": "姓名",
    "请输入姓名": "请输入姓名"
}
