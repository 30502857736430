module.exports = {
    "大": "スポーツカー",
    "小": "飛行機",
    "单": "宝物",
    "双": "ロケット",
    "设置语言": "言語設定",
    "英语": "英語",
    "中文": "中国語",
    "韩语": "韓国語",
    "日语": "日本語",
    "越南语": "ベトナム語",
    "任务单": "タスクリスト",
    "key": "再生: {count}",
    "key2": "人気おすすめ",
    "key3": "人気ランキング",
    "key4": "もっと見る",
    "key5": "おすすめタスク",
    "获取中": "読み込み中......",
    "刷新成功": "更新成功",
    "首页": "ホーム",
    "任务记录": "タスク記録",
    "提现": "出金",
    "我的": "マイページ",
    "投票结果": "投票結果",
    "可用余额": "利用可能残高",
    "提交": "送信",
    "当前选号": "現在の選択番号",
    "每注金额": "ベット金額",
    "请输入金额": "金額を入力してください",
    "总共": "合計",
    "注": "ベット",
    "合计": "小計",
    "清空订单": "注文をクリア",
    "确认提交": "送信を確認",
    "期号": "期番号",
    "开奖号码": "当選番号",
    "未选择": "未選択",
    "金额错误！": "金額エラー！",
    "请选号！": "番号を選択してください！",
    "请填写金额！": "金額を記入してください！",
    "余额不足，请联系客服充值！": "残高不足です。カスタマーサービスに連絡してチャージしてください！",
    "未选中": "未選択",
    "请联系管理员领取该任务!": "管理者に連絡してタスクを受け取ってください！",
    "开奖成功，期号：": "抽選成功、期番号：{count}",
    "key6": "清算時間: {update_time}",
    "key7": "注文時間: {create_time}",
    "key8": "金額: {money}",
    "key10": "利益金額: {money}",
    "数据为空！": "データが空です！",
    "充值": "チャージ",
    "我的钱包": "マイウォレット",
    "详情": "詳細",
    "余额": "残高",
    "个人报表": "個人レポート",
    "账户明细": "アカウント明細",
    "个人中心": "個人センター",
    "信息公告": "お知らせ",
    "在线客服": "オンラインカスタマーサービス",
    "请完成任务单后进入": "タスクリストを完了後にお進みください",
    "请联系客服充值": "カスタマーサービスに連絡してチャージしてください",
    "请设置收款卡!": "受取カードを設定してください！",
    "功能已禁用!": "機能が無効になっています！",
    "账号下线": "アカウントオフライン",
    "登录/注册": "ログイン/登録",
    "登录可享受更多服务！": "ログインしてさらに多くのサービスを利用しましょう！",
    "收款卡信息": "受取カード情報",
    "添加收款卡": "受取カードを追加",
    "提示:请绑定大型商业银行, 如需修改, 请您联系在线客服": "ヒント: 大型商業銀行を連携してください。修正が必要な場合は、オンラインカスタマーサービスに連絡してください。",
    "请设置姓名后再绑定银行卡！": "名前を設定してから銀行カードを連携してください！",
    "请设置提现密码后再绑定银行卡！": "出金パスワードを設定してから銀行カードを連携してください！",
    "填写收款卡": "受取カードを記入",
    "请输入您的收款卡信息": "受取カード情報を入力してください",
    "分行名": "支店名",
    "请输入分行名称": "支店名を入力してください",
    "帐号": "アカウント（普通）",
    "请选择帐号": "アカウント（普通）を選択してください",
    "尊敬的用户, 为了保障您的资金安全, 请您绑定您的真实姓名和设置取款密码, 如果姓名与开户名不一致, 将无法取款": "尊敬するユーザー様、資金の安全を確保するために、実名を連携し出金パスワードを設定してください。名前と口座名義が一致しない場合、出金はできません。",
    "确认绑卡": "カード連携を確認",
    "请输入银行卡号": "銀行カード番号を入力してください",
    "请选择银行": "銀行を選択してください",
    "提现中心": "出金センター",
    "提现记录": "出金記録",
    "提现金额": "出金金額",
    "全部": "すべて",
    "1.单笔限额：最低，最高": "1. 1回の上限：最低{min}ドル、最高{max}ドル",
    "2.提现次数：一天最高提现次": "2. 出金回数：1日最大{num}回",
    "3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "3. 着金時間：通常5分程度、最速2分以内に着金",
    "限额说明": "上限の説明",
    "马上提现": "今すぐ出金",
    "请填写正确的金额": "正しい金額を記入してください",
    "金额": "金額",
    "说明": "説明",
    "提交时间": "送信時間",
    "审核时间": "審査時間",
    "盈利金额": "利益金額",
    "盈利计算公式 : 中奖金额 - 任务金额": "利益計算式 : 当選金額 - タスク金額",
    "任务金额": "タスク金額",
    "充值金额": "チャージ金額",
    "中奖金额": "当選金額",
    "系统公告": "システム通知",
    "基本信息": "基本情報",
    "头像": "アバター",
    "选择头像": "アバターを選択",
    "确定": "確認",
    "真实姓名": "本名",
    "未设置": "未設定",
    "性别": "性別",
    "男": "男性",
    "女": "女性",
    "未知": "不明",
    "绑定信息": "連携情報",
    "已绑定": "連携済み",
    "无": "なし",
    "设置": "設定",
    "基本信息设置": "基本情報設定",
    "登录密码": "ログインパスワード",
    "资金密码": "資金パスワード",
    "语言": "言語",
    "退出登录": "ログアウト",
    "提现密码已设置，需要修改请联系客服": "出金パスワードが設定されています。変更が必要な場合はカスタマーサービスに連絡してください。",
    "已设置": "設定済み",
    "请输入用户名": "ユーザー名を入力してください",
    "请输入密码": "パスワードを入力してください",
    "忘记密码？": "パスワードを忘れましたか？",
    "没有账户？马上注册": "アカウントがありませんか？ 今すぐ登録",
    "登录": "ログイン",
    "请输入用户名！": "ユーザー名を入力してください！",
    "请输入密码！": "パスワードを入力してください！",
    "请输入用户名(6-12个英文字母或数字)": "ユーザー名を入力してください（6～12文字の英数字）",
    "请输入登录密码(6-12个英文字母或数字)": "ログインパスワードを入力してください（6～12文字の英数字）",
    "请输入邀请码": "招待コードを入力してください",
    "我已经知晓并同意\"开户协议\"各项条约": "「アカウント開設契約」の各条項に同意しました",
    "注册": "登録",
    "请输入邀请码！": "招待コードを入力してください！",
    "请勾选下方开户协议！": "以下のアカウント開設契約をチェックしてください！",
    "下拉即可刷新": "下に引っ張って更新",
    "释放即可刷新": "離すと更新",
    "加载中": "読み込み中",
    '请输入资金密码': 'ファンドのパスワードを入力してください',
    "联系": "連絡",
    "7*24小时竭诚为您服务": "7*24時間、心を込めてサービスを提供します",
    "热门推荐": "人気のおすすめ",
    "次播放": "再生回数",
    "姓名": "名前",
    "请输入姓名": "名前を入力してください"
}
