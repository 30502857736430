module.exports = {
    "大": "Sports car",
    "小": "Airplane",
    "单": "Treasure",
    "双": "Rocket",
    "设置语言": "Set Language",
    "英语": "English",
    "中文": "Chinese",
    "韩语": "Korean",
    "日语": "Japanese",
    "越南语": "Vietnamese",
    "任务单": "Task List",
    "key": "Play: {count}",
    "key2": "Popular Recommendations",
    "key3": "Popularity Ranking",
    "key4": "View More",
    "key5": "Recommended Tasks",
    "获取中": "Loading......",
    "刷新成功": "Refresh Successful",
    "首页": "Home",
    "任务记录": "Task Record",
    "提现": "Withdraw",
    "我的": "My",
    "投票结果": "Vote Result",
    "可用余额": "Available Balance",
    "提交": "Submit",
    "当前选号": "Current Selection",
    "每注金额": "Bet Amount",
    "请输入金额": "Enter Amount",
    "总共": "Total",
    "注": "Bets",
    "合计": "Subtotal",
    "清空订单": "Clear Order",
    "确认提交": "Confirm Submit",
    "期号": "Period Number",
    "开奖号码": "Winning Number",
    "未选择": "Not Selected",
    "金额错误！": "Invalid Amount!",
    "请选号！": "Please Select Numbers!",
    "请填写金额！": "Please Enter Amount!",
    "余额不足，请联系客服充值！": "Insufficient Balance, Please Contact Customer Service to Recharge!",
    "未选中": "Unselected",
    "请联系管理员领取该任务!": "Please Contact Administrator to Receive This Task!",
    "开奖成功，期号：": "Draw Successful, Period Number: {count}",
    "key6": "Settlement Time: {update_time}",
    "key7": "Order Time: {create_time}",
    "key8": "Amount: {money}",
    "key10": "Profit Amount: {money}",
    "数据为空！": "No Data!",
    "充值": "Recharge",
    "我的钱包": "My Wallet",
    "详情": "Details",
    "余额": "Balance",
    "个人报表": "Personal Report",
    "账户明细": "Account Details",
    "个人中心": "Personal Center",
    "信息公告": "Information Announcement",
    "在线客服": "Online Customer Service",
    "请完成任务单后进入": "Please Complete the Task List Before Entering",
    "请联系客服充值": "Please Contact Customer Service to Recharge",
    "请设置收款卡!": "Please Set Up a Payment Card!",
    "功能已禁用!": "Feature Disabled!",
    "账号下线": "Account Logged Out",
    "登录/注册": "Login/Register",
    "登录可享受更多服务！": "Login to Enjoy More Services!",
    "收款卡信息": "Payment Card Information",
    "添加收款卡": "Add Payment Card",
    "提示:请绑定大型商业银行, 如需修改, 请您联系在线客服": "Note: Please Bind a Major Commercial Bank. For Modifications, Contact Online Customer Service.",
    "请设置姓名后再绑定银行卡！": "Please Set a Name Before Binding a Bank Card!",
    "请设置提现密码后再绑定银行卡！": "Please Set a Withdrawal Password Before Binding a Bank Card!",
    "填写收款卡": "Fill in Payment Card",
    "请输入您的收款卡信息": "Please Enter Your Payment Card Information",
    "分行名": "Branch Name",
    "请输入分行名称": "Please Enter Branch Name",
    "帐号": "Account (Regular)",
    "请选择帐号": "Please Select Account (Regular)",
    "尊敬的用户, 为了保障您的资金安全, 请您绑定您的真实姓名和设置取款密码, 如果姓名与开户名不一致, 将无法取款": "Dear User, To Ensure Your Fund Security, Please Bind Your Real Name and Set a Withdrawal Password. Withdrawals Will Not Be Possible If the Name Does Not Match the Account Holder Name.",
    "确认绑卡": "Confirm Card Binding",
    "请输入银行卡号": "Please Enter Bank Card Number",
    "请选择银行": "Please Select a Bank",
    "提现中心": "Withdrawal Center",
    "提现记录": "Withdrawal Records",
    "提现金额": "Withdrawal Amount",
    "全部": "All",
    "1.单笔限额：最低，最高": "1. Per Transaction Limit: Minimum {min}$, Maximum {max}$",
    "2.提现次数：一天最高提现次": "2. Withdrawal Frequency: Maximum {num} Times Per Day",
    "3.到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "3. Processing Time: Usually Around 5 Minutes, Fastest Within 2 Minutes",
    "限额说明": "Limit Explanation",
    "马上提现": "Withdraw Now",
    "请填写正确的金额": "Please Enter the Correct Amount",
    "金额": "Amount",
    "说明": "Description",
    "提交时间": "Submission Time",
    "审核时间": "Review Time",
    "盈利金额": "Profit Amount",
    "盈利计算公式 : 中奖金额 - 任务金额": "Profit Formula: Winnings - Task Amount",
    "任务金额": "Task Amount",
    "充值金额": "Recharge Amount",
    "中奖金额": "Winning Amount",
    "系统公告": "System Announcement",
    "基本信息": "Basic Information",
    "头像": "Avatar",
    "选择头像": "Select Avatar",
    "确定": "Confirm",
    "真实姓名": "Real Name",
    "未设置": "Not Set",
    "性别": "Gender",
    "男": "Male",
    "女": "Female",
    "未知": "Unknown",
    "绑定信息": "Binding Information",
    "已绑定": "Bound",
    "无": "None",
    "设置": "Set",
    "基本信息设置": "Basic Information Settings",
    "登录密码": "Login Password",
    "资金密码": "Funds Password",
    "语言": "Language",
    "退出登录": "Log Out",
    "提现密码已设置，需要修改请联系客服": "Withdrawal Password Set. Contact Customer Service to Modify.",
    "已设置": "Set",
    "请输入用户名": "Please enter username",
    "请输入密码": "Please enter password",
    "忘记密码？": "Forgot password?",
    "没有账户？马上注册": "Don't have an account? Register now",
    "登录": "Login",
    "请输入用户名！": "Please enter username!",
    "请输入密码！": "Please enter password!",
    "请输入用户名(6-12个英文字母或数字)": "Please enter username (6-12 letters or numbers)",
    "请输入登录密码(6-12个英文字母或数字)": "Please enter login password (6-12 letters or numbers)",
    "请输入邀请码": "Please enter invitation code",
    "我已经知晓并同意\"开户协议\"各项条约": "I have read and agree to the terms of the \"Account Opening Agreement\"",
    "注册": "Register",
    "请输入邀请码！": "Please enter invitation code!",
    "请勾选下方开户协议！": "Please check the Account Opening Agreement below!",
    "下拉即可刷新": "Pull down to refresh",
    "释放即可刷新": "Release to refresh",
    "加载中": "Loading",
    '请输入资金密码': 'Please enter the fund password',
    "联系": "Contact",
    "7*24小时竭诚为您服务": "7*24 hours of dedicated service for you",
    "热门推荐": "Popular Recommendations",
    "次播放": "Views",
    "姓名": "Name",
    "请输入姓名": "Please enter your name"
}

