import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import { i18n } from '@/lang/index.js'
import 'video.js/dist/video-js.css'
import '@vime/core/themes/default.css'; // 引入 Vime 的默认样式


Vue.prototype.$http = http
Vue.prototype.common = common
// Vue.prototype.$domain = 'http://kongjianght.guogancs015.xyz' // 测试环境
Vue.prototype.$domain = 'https://api.youtubepa.xyz' // 正式环境
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
Vue.use(i18n);
new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
}).$mount('#app')
